<template>
  <div class="app-container">
    <div class="head-container">
      <quick-select v-model="query.buyerId" url="api/distributor" placeholder="经销商" clearable filterable @change="toQuery" value-field="enterpriseId" class="filter-item" style="width: 180px;" />
      <quick-select v-model="query.buyerErpCode" url="api/distributorErps/list" :params="{entId:query.buyerId}" placeholder="退单账户" value-field="erpId" display-field="name" @change="toQuery" class="filter-item"  style="width: 180px;" clearable v-if="query.buyerId"/>              
      <el-date-picker v-model="query.dateRange" type="daterange" class="filter-item" unlink-panels range-separator="至" start-placeholder="创建开始日期" end-placeholder="创建结束日期" :picker-options="searchDateRangeOptions" value-format="timestamp" :default-time="['00:00:00', '23:59:59']" @change="toQuery" />
      <el-input v-model="query.formCode" class="filter-item" :maxlength="20" placeholder="输入单号搜索" @keypress.enter.native="toQuery" style="width: 160px;" />
      <el-button class="filter-item" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-download" :loading="downloadLoading" @click="toDownload">导出</el-button>

      <div style="display: inline-block;margin: 0px 2px;" v-permission="['RETURNMANAGE_ALL','RETURNMANAGE_CREATE']">
        <el-dropdown szie="mini" type="primary" @click="add" @command="handleAddCmd" class="filter-item">
          <el-button type="primary">新增退货单</el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="order">销售单退货</el-dropdown-item>
            <el-dropdown-item command="no-source">无来源退货</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>

    <el-table v-loading="loading" row-key="id" :data="data" style="width: 100%;">
      <el-table-column prop="formCode" label="退换单号" width="140" fixed="left" />
      <el-table-column prop="orderFormCode" label="关联订单号" width="150" />
      <el-table-column prop="buyerErpCode" label="经销商ERP编码" width="150" />
      <el-table-column prop="buyerName" label="经销商" width="240" show-overflow-tooltip />
      <el-table-column prop="totalMoney" label="应退现金" width="120" align="right" :formatter="$price" />
      <el-table-column prop="realBackMoney" label="实退现金" width="120" align="right" :formatter="$price" />
      <el-table-column prop="rebateAmount" label="应退返利金" width="120" align="right" :formatter="$price" />
      <el-table-column prop="createAt" label="创建日期" width="120" :formatter="r => { return new Date(r.createAt).format('yyyy/MM/dd'); }" />
      <!-- <el-table-column label="发货地址" min-width="240">
        <template slot-scope="scope">{{scope.row.sendProvince}}{{scope.row.sendCity}}{{scope.row.sendDistrict}}{{scope.row.sendAddress}}</template>
      </el-table-column>-->
      <el-table-column prop="info" label="备注" min-width="240" />
      <el-table-column label="已发货" width="60" align="center">
        <template slot-scope="scope">
          <i class="fa fa-check" v-if="scope.row.isSend"></i>
        </template>
      </el-table-column>
      <!-- <el-table-column label="已收货" width="60" align="center">
        <template slot-scope="scope">
          <i class="fa fa-check" v-if="scope.row.isReceive"></i>
        </template>
      </el-table-column>-->
      <el-table-column label="已退款" width="60" align="center">
        <template slot-scope="scope">
          <i class="fa fa-check" v-if="scope.row.isRefund"></i>
        </template>
      </el-table-column>
      <el-table-column label="状态" width="90" align="center" fixed="right">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.backType==='fix'" :type="repairStatus[scope.row.status].type">{{repairStatus[scope.row.status].label}}</el-tag>
          <el-tag v-else :type="status[scope.row.status].type">{{status[scope.row.status].label}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column width="150" fixed="right">
        <div class="row-commands" slot-scope="scope">
          <template v-if="scope.row.status === 0 ">
            <el-button v-permission="['RETURNMANAGE_ALL','RETURNMANAGE_EDIT']" size="mini" type="text" @click="edit(scope.row)">编辑</el-button>
            <el-popover v-permission="['ADMIN','RETURNMANAGE_ALL','RETURNMANAGE_DELETE']" :ref="scope.row.id" placement="top" width="180">
              <p>确定删除本条数据吗？</p>
              <div style="text-align: right; margin: 0">
                <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
                <el-button :loading="delLoading" type="primary" size="mini" @click="subDelete(scope.row.id)">确定</el-button>
              </div>
              <el-button slot="reference" class="danger" type="text" size="mini">删除</el-button>
            </el-popover>
          </template>
          <el-button v-else size="mini" type="text" @click="edit(scope.row)">查看</el-button>
          <el-button size="mini" type="text" v-permission="['RETURNMANAGE_ALL','RETURNMANAGE_AUDIT']" @click="edit(scope.row, 'handle')" v-if="scope.row.status === 1">受理</el-button>

          <template v-if="scope.row.status === 2">
            <!-- <el-button size="mini" v-permission="['RETURNMANAGE_ALL','RETURNMANAGE_INBOUND']" type="text" @click="edit(scope.row, 'inbound')" v-if="!scope.row.isReceive">入库</el-button> -->
            <el-button size="mini" v-permission="['RETURNMANAGE_ALL','RETURNMANAGE_VERIFY']" type="text" @click="edit(scope.row, 'verify')">审批</el-button>
          </template>
          <template v-if="scope.row.status === 4">
            <el-button size="mini" v-permission="['RETURNMANAGE_ALL','RETURNMANAGE_RETURNMONEY']" type="text" @click="edit(scope.row, 'refund')" v-if="scope.row.backType === 'returnMoney' && !scope.row.isRefund">退款</el-button>
            <!-- <el-button size="mini" v-permission="['RETURNMANAGE_ALL','RETURNMANAGE_FINISH']"  type="text" @click="edit(scope.row, 'finish')">完成</el-button> -->
          </template>
          <el-button size="mini" type="text" @click="doCost(scope.row)">费用单</el-button>
        </div>
      </el-table-column>
    </el-table>

    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />

    <e-form ref="form" />
    <costs ref="costs" />
    <reference-order ref="referenceOrder" @select="addByOrder" />
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import eForm from "./form";
import costs from "./costs";
import { del } from "@/api/orderBackForm";
import referenceOrder from "./reference-order";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";

export default {
  components: { eForm, referenceOrder, costs },
  mixins: [initData],
  data() {
    return {
      downloadLoading: false,
      delLoading: false,
      searchDateRangeOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      // 状态与操作  编辑中 审批未通过 、待审批 审批通过 已关闭
      repairStatus: [
        {
          type: "info",
          label: "编辑中",
        },
        {
          type: "warning",
          label: "待受理",
        },
        {
          type: "success",
          label: "已同意",
        },
        {
          type: "danger",
          label: "已拒绝",
        },
        {
          type: "success",
          label: "处理中",
        },
        {
          type: "primary",
          label: "维修已发出",
        },
        {
          type: "primary",
          label: "维修部分发出",
        },
        {
          type: "primary",
          label: "厂家已收货",
        },
      ],
      status: [
        {
          type: "info",
          label: "编辑中",
        },
        {
          type: "warning",
          label: "待受理",
        },
        {
          type: "success",
          label: "已同意",
        },
        {
          type: "danger",
          label: "已拒绝",
        },
        {
          type: "success",
          label: "处理中",
        },
        {
          type: "primary",
          label: "已完成",
        },
      ],
      query: {
        buyerId: null,
        dateRange: null,
        formCode: null,
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = "api/orderBackForm";
      let query = JSON.parse(JSON.stringify(this.query));
      if (query.dateRange && query.dateRange.length === 2) {
        query.beginTime = query.dateRange[0];
        query.endTime = query.dateRange[1];
      }
      delete query.dateRange;
      this.params = Object.assign(
        { backType: 0, sort: "createAt,desc" },
        query
      );
      return true;
    },

    subDelete(id) {
      this.delLoading = true;
      del(id)
        .then((res) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.dleChangePage();
          this.init();
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2500,
          });
        })
        .catch((err) => {
          this.delLoading = false;
          this.$refs[id].doClose();
        });
    },
    handleAddCmd(ac) {
      if (ac === "order") this.add();
      else if (ac === "no-source")
        this.$refs.form && this.$refs.form.resetForm();
    },
    add() {
      this.$refs.referenceOrder && this.$refs.referenceOrder.show();
    },
    addByOrder(order) {
      this.$refs.form && this.$refs.form.resetForm(null, order);
    },
    edit(data, action = null) {
      this.$refs.form && this.$refs.form.resetForm(data, null, action);
    },
    doCost(order) {
      this.$refs.costs &&
        this.$refs.costs.resetForm(
          order,
          this.checkPermission(["RETURNMANAGE_ALL", "RETURNMANAGE_EDIT"])
        );
    },
    toDownload() {
      if (this.data && this.data.length === 0) {
        this.$message.info("当前无数据导出");
        return;
      }
      this.downloadLoading = true;
      download("api/orderBackForm/download", this.params)
        .then((result) => {
          downloadFile(result, "批发退货数据", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
  },
};
</script>